import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kingCall',
    name: 'Kingcall',
    component: () => import(/* webpackChunkName: "invite" */ '../views/kingCall.vue')
  },
  {
    path: '/farmyard',
    name: 'farmyard',
    component: () => import(/* webpackChunkName: "invite" */ '../views/farmyard.vue')
  },
  {
    path: '/klp',
    name: 'Klp',
    component: () => import(/* webpackChunkName: "invite" */ '../views/klp.vue'),
    meta: {
      title: '快乐派短视频'
    }
  },
  {
    path: '/xyxq',
    name: 'xyxq',
    component: () => import(/* webpackChunkName: "invite" */ '../views/xyxq.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
